import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Macey from "../images/macey"
import { useSpring, animated, config } from "react-spring"
import { Container, Layout, PageDivider, Section } from "../components"
import SEO from "../components/seo"

const PortfolioPage: React.FunctionComponent = () => {
  const fadeUp = useSpring({
    transform: "translateY(0)",
    opacity: 1,
    from: { transform: "translateY(0px)", opacity: 0 },
    config: config.molasses,
  })
  return (
    <Layout>
      <SEO title="Portfolio" />
      <animated.div style={fadeUp}>
        <Section backgroundColor="var(--gray-900)" minHeight="300">
          <Container>
            <FlexContainer>
              <FlexContent>
                <h2>My Portfolio</h2>
                <p>
                  Of course he's a happy little stone, cause we don't have any
                  other kind. Just think about these things in your mind and
                  drop em' on canvas. Pretend you're water. Just floating
                  without any effort. Having a good day. They say everything
                  looks better with odd numbers of things. But sometimes I put
                  even numbers—just to upset the critics. That's the way I look
                  when I get home late; black and blue. There is immense joy in
                  just watching - watching all the little creatures in nature.
                </p>
              </FlexContent>
              <FlexImage>
                <Macey />
              </FlexImage>
            </FlexContainer>
          </Container>
          <div></div>
        </Section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#1a202c"
            fill-opacity="1"
            d="M0,192L48,197.3C96,203,192,213,288,208C384,203,480,181,576,154.7C672,128,768,96,864,80C960,64,1056,64,1152,85.3C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <Section>
          <Container>
            <h3
              css={`
                margin-top: 0;
              `}
            >
              Work
            </h3>
            <Link to="/typography/">To typography</Link>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h2>
              Heading 2 - Maybe there's a little something happening right here
            </h2>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h3>
              Heading 3 - Maybe there's a little something happening right here
            </h3>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h4>
              Heading 4 - Maybe there's a little something happening right here
            </h4>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h5>
              Heading 5 - Maybe there's a little something happening right here
            </h5>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h6>
              Heading 6 - Maybe there's a little something happening right here
            </h6>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <ul className="custom checkmark">
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ul>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ul>
            <ol>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ol>
          </Container>
        </Section>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{ display: "block" }}
        >
          <path
            fill="#1a202c"
            fill-opacity="1"
            d="M0,224L80,240C160,256,320,288,480,261.3C640,235,800,149,960,128C1120,107,1280,149,1360,170.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </animated.div>
    </Layout>
  )
}

const SectionAfterPsuedo = styled(Section)`
  background: green;
`

const FlexContainer = styled.div`
  display: block;
  @media (min-width: ${props => props.theme.responsive.md}) {
    display: flex;
    margin: 0 -10px;
    > div {
      margin: 0 10px;
    }
  }
`
const FlexContent = styled.div`
  color: var(--gray-400);
  padding: 1rem 0;
  flex: 3;
`

const FlexImage = styled.div`
  flex: 1;
  @media (min-width: ${props => props.theme.responsive.md}) {
    transform: translateY(100px);
    width: 100%;
  }
`
export default PortfolioPage
